import { LeadExpressRequest } from './types/lead';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@fundo/environments/environment';
import { Store } from '@ngrx/store';
import { LeadStatusRequest, LeadStatusResponse } from '@shared/enums/lead-status';
import { Observable, map, switchMap, tap } from 'rxjs';

import { updateStatus } from '../states/app-status/actions/app-status.actions';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  private readonly API_BASE = `${environment.apiBaseUrl}/lead`;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private store: Store,
  ) {}

  public getStatus(): Observable<LeadStatusRequest> {
    return this.httpClient
      .get<LeadStatusRequest>(`${this.API_BASE}/status`)
      .pipe(
        tap((response: LeadStatusRequest) =>
          this.store.dispatch(updateStatus({ status: response })),
        ),
      );
  }

  public getStatusWithToken(): Observable<LeadStatusRequest> {
    return this.authService.getAuthorizationToken().pipe(switchMap(() => this.getStatus()));
  }

  public getAppLink(): Observable<string> {
    return this.httpClient.get<string>(`${this.API_BASE}/appLink`);
  }

  public postLeadExpress(data: LeadExpressRequest): Observable<string> {
    return this.httpClient.post<string>(`${this.API_BASE}/express`, data);
  }

  /**
   * Request the redirection of the user to bank verification
   */
  redirectLeadToBankVerification(): Observable<any> {
    return this.httpClient.put<any>(`${this.API_BASE}/redirectBV`, {});
  }
}
